import ModelElement from './v-element'
import { programElementTypes, moveTypes, motionTypes, variableTypes, libraryGroups } from '/@/shared/constants'
import { uuid, rad } from '/@shared/utils'

export default class ModelMove extends ModelElement {
  initVariables() {
    super.initVariables()

    this._type = programElementTypes.move
    this._moveType = moveTypes.MOVE_L.value
    this._motionType = motionTypes.VELANDACC.value
    this._title = 'move'
    this._icon = 'v-move'
    this._waypoints = []
    this._canHaveChildren = true
    this._velocity = 0.1
    this._acceleration = 0.2
    this._rotationalVelocity = rad(10)
    this._rotationalAcceleration = rad(20)
    this._angle = 0
    this._isGeometryRequired = true
    this._sortOrder = 2
    this._libraryGroup = libraryGroups.ROBOT
  }

  addWaypointFromVariable(id, type) {
    // TODO fix method can be different
    const variable = this.methodModel.variables.find((v) => v.id === id && v.type === type)

    this.waypoints.push({
      uuid: uuid(),
      objectID: variable.id,
      objectType: type,
      smoothingFactor: 10,
      segmentVelocity: 100,
    })
  }

  updateWaypoint(waypoint) {
    const waypoints = [...this.waypoints]
    const index = waypoints.findIndex((w) => w.uuid === waypoint.uuid)
    waypoints[index] = waypoint
    this.waypoints = waypoints
  }

  toJS() {
    if (this.waypoints.length === 0) {
      return
    }

    const jointReference = this.getJointReference()

    const moveTypeConstrainStr = `new VelAndAccConstraint(${this.velocity}, ${this.acceleration}, ${this.rotationalVelocity}, ${this.rotationalAcceleration}, 0.1)`
    const wayPointString = this.getWaypointStrings().join(', ')

    var js = `nop(new LineId("${this.id}"));\n`

    // TODO: Move commands aren't allowed as callback functions
    const callback = `(function (counter) {
      ${this.children.map((c) => c.toJS()).join('\n')}
    }).bind(this)\n`

    switch (this.moveType) {
      case moveTypes.MOVE_J.value:
        js += `movej(new MoveJ(${moveTypeConstrainStr}, [${wayPointString}]), ${callback});\n`
        break
      case moveTypes.MOVE_L.value:
        js += `movel(new MoveL(${moveTypeConstrainStr}, ${jointReference}, [${wayPointString}], 0), ${callback});\n`
        break
      case moveTypes.MOVE_C.value:
        js += `movec(new MoveC(${moveTypeConstrainStr}, ${jointReference}, ${this.angle}, [${wayPointString}], 0), ${callback});\n`
        break
    }

    return js
  }

  getJointReference() {
    let reference = 'new JointPositions([])'
    const point = this.geometryModel.find((p) => p.id === this.waypoints[0].objectID)

    if (point) {
      reference = `new JointPositions([${point.jointPose.join(', ')}])`
    }

    return reference
  }

  getWaypointStrings() {
    return this.waypoints
      .map((wayPoint) => {
        return this.enrichWaypoint(wayPoint)
      })
      .map((wayPoint) => {
        let wayPointStr = ''
        let coordinatesStr = ''
        let constraintStr = ''

        switch (this.moveType) {
          case moveTypes.MOVE_J.value:
            coordinatesStr = `new JointPositions(${this.getWayPointJointPosition(wayPoint)})`
            constraintStr = `new WayPointConstraint('TIME', ${wayPoint.smoothingFactor / 100})`
            wayPointStr = `new JointWaypoint(${coordinatesStr}, ${constraintStr}, ${wayPoint.segmentVelocity / 100})`
            break
          case moveTypes.MOVE_L.value:
          case moveTypes.MOVE_C.value:
            coordinatesStr = `new CartPose6(${wayPoint.scopedTitle})`
            constraintStr = `new WayPointConstraint('POSITION', ${wayPoint.smoothingFactor / 100})`
            wayPointStr = `new CartWaypoint(${coordinatesStr}, ${constraintStr}, ${wayPoint.segmentVelocity / 100})`
            break
        }

        return wayPointStr
      })
  }

  enrichWaypoint(waypoint) {
    const actualTitle = this.methodModel.getActualWaypointTitle(waypoint.objectID, waypoint.objectType)

    const scopedTitle = [variableTypes.GEOMETRY, variableTypes.ARGUMENT_CONSTRUCTOR].includes(waypoint.objectType)
      ? `this.${actualTitle}`
      : actualTitle

    waypoint.title = actualTitle
    waypoint.scopedTitle = scopedTitle

    return waypoint
  }

  getWayPointJointPosition(wayPoint) {
    const point = this.geometryModel.find((p) => p.id === wayPoint.objectID)

    if (point) {
      return `[${point.jointPose.join(', ')}]`
    }

    return wayPoint.scopedTitle
  }
}
